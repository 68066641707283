body {
    margin: 0;
    font-family: "Helvetica Neue",Helvetica,Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import 'css/colors.css';
@import 'css/loginPage.css';
@import 'css/print.css';

.adScreenshotImg {
    width: 100%;
}

.adViolationReport table tr:nth-child(odd) {
    background-color: var(--input-background-disabled);
}

.tableActionButton {
    font-size: .875rem;
    color: var(--accent-green-two);
    font-weight: bold;
}