:root {
    --dark-green: #0e673b;
    --gray: #4f5a50;
    --error: #670e0e;
    --accent-green-one: #8dcca0;
    --accent-green-two: #3fb44f;
    --accent-green-three: #8dc549;

    --input-text: #495057;
    --input-background: #fff;
    --input-border: #ced4da;

    --input-text-disabled: #a6aaad;
    --input-background-disabled: #f2f2f2;
    --input-border-disabled: #e6e6e6;

    --input-helper-text: #7b7b7b;

    --input-placeholder: #aab7b8;
}