div[class^="Input__inputLabel"] {
    color: var(--dark-green);
}

div[class^="Form__formSection"] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

span[class^="Section__sectionFooterSecondaryContent"], div[class^="Hint__hint"] {
    display: none;
}

button[class^="Button__button"] {
    padding: 10px 0;
    background-color: var(--dark-green);
    width: 100%;
}

input[class^="Input__input"] {
    padding: 10px;
}

span[class^="Section__sectionFooterPrimaryContent"] {
    margin: 0px;
    width: 100%;
}

div[class^="Section__sectionFooter"] {
    flex-direction: column;
}

div[class^="Form__formContainer"]::before {
    display: block;
    content: "";
    background: url('../images/weblogo_transparent.png') no-repeat 0 0;
    background-size: 100%;
    margin: auto;
}

div[class^="Nav__navBar"] button {
    width: auto;
}

@media only screen and (max-width: 599px) {
    div[class^="Form__formContainer"]::before {
        margin-top: 50px;
        width: 95%;
        height: 118px;
    }
}

@media only screen and (min-width: 600px) {
    div[class^="Form__formContainer"]::before {
        width: 30rem;
        --width: 30rem;
        height: calc(var(--width) * .25)
    }
}

span[class^="Section__sectionHeaderContent"] {
    display: none;
}

button[class^="Button__button"]:active {
    background-color: var(--dark-green);
    opacity: 0.9;
}

button[class^="Button__button"]:hover {
    background-color: var(--dark-green);
    opacity: 0.9;
}

button[class^="Button__button"]:disabled {
    background-color: var(--dark-green);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

.adcomplyrx-link {
    text-align: center;
    margin: 1rem auto;
}

.adcomplyrx-link > a {
    text-decoration: none;
    color: var(--dark-green);
    font-size: .90rem;
}
