@media print {
    html, body, .MuiTableCell-root {
        font-size: 12px !important;
    }
    #root > div {
        padding: 0px;
    }
    main > div > div {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .adViolationReport {
        page-break-before: always;
    }
    .adViolationReport table {
        page-break-after: avoid;
    }
    .noPrint, .MuiDrawer-root
    {
        display: none !important;
    }
    .nonComplianceReport .MuiPaper-root {
        box-shadow: none;
    }
    @page {
        size: portrait;   /* auto is the initial value */
    }
}